/**** Responsive ****/
@media (min-width: 1601px) {
    h1 {font-size: 42px;}
	h2 {font-size: 35px;}
	h3 {font-size: 26px;}
	h4,
    .order_amt h3 {font-size: 22px;}
	h5,
    .home-top-about .about-txt p,
    .head_right h4 {font-size: 17px;}
    .home-top-about .about-txt  h1 {font-size: 70px;}
    .home-top-about .about-txt h4,
    .home-option-desc h5,
    .client-abt .client-abt-txt h6  {font-size: 27px;}
    .reward-list-body .order_amt_div h3 {font-size: 22px;}
    .home-options-inner h3,
    .client_riv .client_riv_title h3 {font-size: 59px;}
    .home-option-desc .home_desc  {font-size: 15px;}
    .home-order-btn .home-btn {font-size: 19px;}
    .client_riv .client_riv_slider .client_riv_item p {font-size: 18px;margin-bottom: 75px;}
    .client-abt .client-abt-txt .client-btn,
    .head_left h4  {font-size: 19px;}
    .client-abt::before  {border-top: 210px solid #fff;}
    input[type="reset"],
    input[type="button"],
    input[type="submit"],
    .button,
    button {padding: 14px 24px;}
    .footer-nav-info .foot-row h5 {font-size: 20px;}
    .common-inner-banner h1,
    .common-inner-banner p {font-size: 59px;}
    .common-inner-banner span {font-size: 16px;}
    .order_btns .button {padding: 16px 10px 16px;}
    .tick h2 {font-size: 44px;}
    .mainacc_toptext p {font-size: 17px;}
    .client-abt .client-abt-img .client-img-2 {right: -50px;}
}
@media (min-width: 1200px){        
.mobile_hmenu_list{ display: none;}/* Mobile cms menu */
.hmenu_list > li:hover > a > .hdd_arrow{ opacity: 1; visibility: visible;}
}
@media (min-width: 679px){
.cur-ord-rec-mobile{ display: none;}
}   
@media (min-width: 576px){    
.modal-dialog { margin: 1.75rem auto; }
.modal-dialog-centered { min-height: calc(100% - (1.75rem * 2)); min-height: -webkit-calc(100% - (1.75rem * 2));}
}
@media(max-width: 1330px) and (min-width: 991px){
.promation_btn .btn {min-width: auto; width: 48%; float: left; margin: 1%; font-size: 16px; padding: 12px 10px;}
}
/* @media (min-width: 1500px){   
.container-full{ padding-left: 0; padding-right: 0  } 
} */
/* Max width */
@media (max-width: 1350px){
.container, .container-full{padding-left: 15px; padding-right: 15px;}
}

@media (max-width: 1280px){
ul.hmenu_list > li > a, .hsign_sec a{font-size: 14px;}
.hordertype_sec a { padding: 10px 20px;  margin: 0 15px 0 20px;font-size: 14px;}    
.htico_cart,
.htico_search {margin-left: 10px}    
.pdr_right.price-fixed{left: inherit !important; right: 0}    
}
@media (max-width: 1240px){
.header_in::before, .header_in::after, .ourstory-inner-cont:before{display: none}	
.client_riv .client_riv_slider .client_riv_item .client_riv_icon {flex-direction: column;justify-content: flex-end;align-items: center;}
.client_riv .client_riv_slider .client_riv_item .client_riv_icon img {margin-bottom: 5px;}
}
@media (max-width: 1199px){
input{ -webkit-appearance: none; -moz-appearance: none; appearance: none;  border-radius:0;}
.sticky .alert_fixed{ top: 95px;}
.header_in{ padding: 0 }
.header_middle:before, header.small_sticky:before{display: none !important}    
.hmenu_actions{float: right}
.product-view-more {  margin-top: 15px;}    
.tbars header{ height: 153px;}
.breadcrumb{ padding: 25px 0 0;}
.Products .breadcrumb{ padding: 69px 0 0 0;}
.Products .bread_space { margin: 69px 0 0; }
.hmenu_actions > li.hsign_sec{ display: none;}
.mega_menuin [class*='col-']{width: 100%;}
.logo{width: 100%;max-width:120px;}
.hmenu_actions li.horder_btn{display: none;}
.hsearch_bx .re_select .Select-control:before{ right: 14px;}
i.hdd_arrow{ position: absolute; right: 0; top: 0; bottom: 0; height: 45px; width: 45px; line-height: 45px; font-size: 16px; text-align: center; background: #ecdfcc; color: #181818; margin: auto 0;}
.hsearch_trigger:before{ top: 36px; right: 12px;}
.hsearch_bx .re_select .Select-control{ height: 49px;}
.hsearch_bx .re_select .Select-placeholder{ line-height: 49px;}
.hsearch_bx .re_select .Select-input{ height: 49px;}
/* Sticky Header */
.bakery_row_fixed{ top: 162px;}
.tbars .bakery_row_fixed{ top: 202px;}
.banner{ margin-top: 25px;}
.banner .container{ padding: 0 15px;}
.banner-top-text{position: static;left: 0;right: 0;margin: 0 auto 50px auto;width: 100%; padding: 0 50px;}    
.banner-text-box{margin-right: 0;}
.featured-products-main h2, .serving-passionately-right h3{font-size: 40px; }
.banner-info p{font-size: 22px}
.featured-products-full{ width: 100%}
.delivery_total.delivery_total_number .delivery_total_left h2{font-size: 15px;}
.delivery_total.delivery_total_number .delivery_total_left h4{font-size: 28px;}
.innerproduct-col{width: 32.29061553985873%;margin-right: 1.5136226034308779%;margin-bottom: 15px;}
.innerproduct-col:nth-of-type(3n+3){margin-right: 0;}
.innerproduct-col:nth-of-type(5n+5){margin-right: auto;}
.chk_hea{font-size: 16px;}
.checkout-total .cdd-details-lhs{width: 100%;margin-bottom: 10px;} 
.cdd-details-lhs .checkout-heading:after{display: none}    
.check_pg_inner{padding: 20px 15px 10px 15px;}
.checkout-total .checkout-control-group-top{padding: 20px 0 20px 0;}
.checkout-total .cdd-details-rhs{width: 100%;}
.checkout-total .checkout-control-group-middle{padding: 10px 0 0px 0;}
.checkout-control-group-top [class*='col-md']{margin-bottom: 10px;}
.checkout-control-group-middle [class*='col-md']{margin-bottom: 10px;}
.checkout-total .checkout-control-group-top a{margin-top: 0;}
.order-detail-inner-icon{max-width: 100%;}
.order-detail-inner-icon-left.order-detail-inner-center::before{background-repeat: repeat;max-width: 100%;height: 6px;}
.order-detail-inner{padding-top: 27px;}
.main_tabsec_inner> .nav-tabs>li> a{text-align: left;}
.main_tabsec_inner, .rewards-tab-section .main_tabsec_inner, .promation-tab-section .main_tabsec_inner{padding: 20px 15px;}
.title_sec h2{font-size: 34px;}
.filter_tabsec .nav-tabs>li>a{font-size: 17px;}
.hme-product-inner .filter_tabsec .nav-tabs{margin-bottom: 20px;}
.banner-left{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.banner-text-box{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;width: 100%;}
.banner-right{margin-top: 0;}
.banner-right-content{text-align: left;}
.htopinfo{text-align: center;width: 100%;}
.delivery-item h2{font-size: 32px;}
.delivery-item span{font-size: 17px;}
.hme-product-inner{padding: 20px 15px;}
.outlet-list li{ padding-left: 0;}
.footer-inner [class*='col-']{width: 33.33%;}
.footer-inner .news-letter{width: 100%;margin-top: 10px;}
.cart_footer_totrow p{font-size: 20px;}
.cart_footer_totrow span{font-size: 20px;}
.delivery_info h4{font-size: 14px;}
.delivery_info p{font-size: 14px;}
.cart_action .btn_grey{font-size: 14px;}
.tnk-you{padding: 70px 0;}
.redeem-col{width: 100%;margin: 0 0 10px 0;padding: 20px 10px;}
.redeem-item-hea{padding-left: 0;}
.redeem-item-hea h4{font-size: 23px;}
.tick img{width: 60px;}
.tick h2{font-size: 34px;margin-top: 10px;}
.tick span{font-size: 16px;}
.delivery-inner{padding: 40px 0;position: relative;}
.delivery-inner-comman{position: relative;z-index: 2;}
.delivery-inner::after{content: "";background: rgba(255, 255, 255, 0.6);top: 0;left: 0;bottom: 0;right: 0;position: absolute;z-index: 1;}
.bts .form-group {min-width: auto;width: calc(100% - 200px) !important;}
/*promotion pages*/
.promo-earned-content{padding: 10px 10px; }
.promo-earned-content h2, .promo-earned-content span{ font-size: 20px; }
.promo-earned-content h2 span sup {font-size: 20px; }
.abc_code span{ font-size: 17px; }
.abc_code{ padding: 10px 0; }
.redeem_popsec .promo-earned-content h2 span sup {font-size: 18px; }
.redeem_popsec .promo-earned-content h4{ font-size: 24px; }
.redeem_popsec .promo-earned-content p{ margin: 0; }
.redeem_popsec .abc_code {padding: 6px 0 0px; }
.redeem_popsec .promo-earned-content{ padding: 20px 10px 20px; }
.redeem_popsec .promo-earned-content h2{ font-size: 30px;}
/* Account information page */

.pkg_ginfo p{ max-height: 75px; overflow: hidden}    
.header-bottom .logo{display: none}    
.mobile-logo-div{display: -webkit-box !important;display: -ms-flexbox !important;display: flex !important;text-align: center; position: absolute;left: 0;right: 0; max-width: 210px; margin: auto;top: 5px;bottom: 5px; -webkit-box-align: center; -ms-flex-align: center; align-items: center}       
.mobile-logo-div a{display: block;width: 100%;}    
.mobile-logo-div a img{ width: 100% }       
.productlist-main-div{ width: 100%}    
}
@media (max-width: 1180px){
.pkselected_package .buffet-label {padding: 15.5px 15px 15.5px 60px;width: 130px;}
.pkselected_package .form-group {/*width: calc(100% - 135px);*/ min-width: auto;}
.pkselected_filtersec .time-label {width: 171px;}
.pkselected_break .form-group {min-width: auto; /*width: calc(100% - 175px);*/}
.row-pgk-list > li, .pkg_gcol { width: 33.333333333333%;}    
}
@media (max-width: 1100px){
.order_delivery_mobile_tooltip, .selfcollect_tooltip .order_delivery_mobile_tooltip{top: -119px; left:-13px; right:-13px; height: 110px; opacity:1; visibility:visible; pointer-events:auto;}
.order_delivery_mobile_tooltip:before, .selfcollect_tooltip .order_delivery_mobile_tooltip:before{ top: auto; right: auto; left: 50%; bottom: -16px; border-color: #181818 transparent transparent transparent; border-width: 10px 7px 10px 7px; margin: 0 0 0 -7px;}
.order_delivery_mobile_tooltip p{ font-size: 13px; }	
.chekout_cart_bar .cart_extrainfo{padding-left: 0px;}
.ord-amount [class*='col-']{width: 100%;}
.earned_points{float: none;}
.ord-rec-sec{float: none;width: 100%; margin-top: 10px;}
.sprite_lst_icon{display: none} 
.f-nav > ul > li { padding-right: 20px;}    
.productlist-main-div{ padding: 25px 20px 20px;}    
.productlist-main-div .products-list-ulmain{justify-content: space-between;}
.productlist-main-div .products-list-ulmain > li{width:49%;margin: 0 0 20px 0;}    
.product-rhs { width: 350px;}    
.product-lhs { width: calc( 100% - 365px );}  
.home-top-about {padding: 0 15px;}  
}

@media (max-width: 1080px){
.mobile_accountdel_body, .mobile-order-nowbtn, .mobile-login{display: block;}
.header_bot{display: block; margin: 15px 0 0 0;}       
.hordertype_sec, .f-address-img{display: none}  
.logo-main-section{-webkit-box-align: center;-ms-flex-align: center;align-items: center}       
.htico_cart,
.htico_search { margin-left: 7px;padding-left: 7px;}    
.home-options-tab ul li{font-size: 17px}    
.f-address-def { padding: 10px 5px 0 0px;}    
.mobile-login ul{right: 0;}   
.mobile-login {
    /* position: relative; */
    position: absolute;
    left: 40px;
    background: #fff;
    border: 1px solid #DADADA;
    border-radius: 50%;
    width: 43px;
    height: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
}    
.mobile-login ul.active li.hsign_sec {display: block;}
.hsign_sec {display: none;}
a.controller-nav img { width: 23px;}    
.home-options {padding: 30px 0 30px 0;}    
.home-options:before{ height: 105px; background: url(../images/resposive-yellow-strip.jpg) no-repeat; }    
.menu-section-left{display: none}    
.menu-section-mobile-inner, .mobile-menu-section-left{display: block}    
.product-lhs-inner .menu-section-left {display: none;}
.spm-lhs{width: 340px}    
.product_det_cards > ul{justify-content: space-between}    
.product_det_cards > ul li.det_card{width: 49%; margin: 0 0 20px 0;}    
.choose-option-hover{width: 260px}    
.imp-button a{font-size: 12px}
.imp-button a img { max-width: 24px; max-height: 22px;}
.choose-option-hover ul{padding: 5px}
.choose-option-hover ul li a{font-size: 13px;font-family: 'avantgarde_bk_btbook';}
.choose-option-hover ul li { margin: 4px 0;}
.pdr_right.price-fixed{top: 75px}    
.client-abt .client-abt-img .client-img-1 {max-width: calc(100% - 10vw);}
.client-abt .client-abt-img .client-img-2 {max-width: calc(100% - 10vw);}
}

@media (max-width: 1024px){
.delivery{margin-top: 30px;}    
.prd_chosen_row{padding: 25px 20px 20px 20px;}
.congrats.redeem-promation-body{padding: 24px 0;}    
.prd-sub{max-width: 100%;}
.prd-sub .slick-prev{z-index: 1; background: rgba(255,255,255,0.75); left: 0;}
.prd-sub .slick-next{z-index: 1; background: rgba(255,255,255,0.75); right: 0;}
.product_chosen_hea h6{font-size: 14px;}
.tnk-you{padding:45px 0 30px 0;}
.number-break .back_bx {left: 15px; width: 55px; height: 60px; font-size: 14px; padding: 40px 10px 10px;}
.number-break .back_bx:after {top: 10px; font-size: 26px;}
.number-break {padding: 25px 30px 25px 45px;}  
.award_galimg{ height: 250px }   
.footer-nav-info .foot-row .foot-col-social {order: 1;} 
.footer-nav-info .foot-row .foot-sub-col-row {order: 2;}
.footer-nav-info .foot-row .foot-total-col {justify-content: flex-start;}
}
@media (max-width: 991px){
    .products_sidebar_inner ul li a, .slick-slider .products_sidebar_content a, .menu-section-catering.menu-section-left .menu-section-left-inner .menu-list-intvl .menu-title-link{
        font-size: 16px;
    }
    .products-sidebar-more-menu{
        display: none;
    }
    .product-menu-listing .catering-product-list .products_sidebar_inner{
        width: 100%;
    }
    .htico_search a{
        width: 36px;
        height: 36px;
    }
    .htico_search> a > img{
        width: 19px;
        height: 21px;
    }
    .htico_cart > a{
        width: 36px;
        height: 36px;
    }
    .htico_cart> a img{
        width: 20px;
        height: 24px;
    }
    .hcart_round{
        top: -10px!important;
        min-width: 21px;
        min-height: 21px;
    }
    .mobile-login{
        width: 36px;
        height: 36px;
    }

.hbslider_sec h2{ font-size: 20px;}	
.container_720 { max-width: 100%;}

/*--category--*/
.pkg_ginfo h4 {font-size: 16px;margin: 0 0 10px;}
.pkgselect_dd .chosen-container {font-size: 14px;}
.pkgselect_dd .chosen-container-single .chosen-single {font-size: 14px;}
.pkg_gbtsec .btn {font-size: 14px; min-width: 170px;height: 42px;}
.pkg_ginfo .btn {font-size: 14px; padding: 9px 20px 7px;height: 34px;}
/*--update your package--*/
.pkg_listsec .bx_in {padding: 15px;}
.pkglist_itemtable > div {display: block;}
.pkglist_img {width: 100%; padding: 0px 0px 10px;}
.pkglist_info {width: 100%; padding: 15px 0px 0px;}
.pkglist_pricebx {width: 100%; border-left: 1px solid transparent; padding: 0px 0px; text-align: left;}
.pkselect_tabin {padding: 15px 0;}
.package_checkbx .bx_in {padding: 15px 15px;}
.package_formtt {font-size: 17px;}
.package_checkitem {padding: 15px 15px;}
.package_checkbx .package_formchecklist .custom_checkbox span {font-size: 14px;padding: 5px 0 0 40px;}
.package_checkbx .package_formchecklist .custom_checkbox span b {font-size: 14px;}
.package_addonbx .bx_in {    padding: 20px 15px;}
.package_setupbx .bx_in { padding: 20px 15px;}
.package_descbx .bx_in { padding: 20px 15px;}
.title4 {  font-size: 16px;}

.form-group [class*="col-md-"]{ margin-bottom: 8px;}
.form-group [class*="col-md-"]:last-child { margin-bottom: 0; }    
.delivery-item span{ font-size: 14px;}
.delivery-item h2{ font-size: 24px;}
.delivery-item p{ font-size: 16px;}
/* Tab sec */
.nav-tabs{ display: none;}
.tab-content>.tab-pane{opacity: 1; margin: 0 0 15px;}
.tab_mobtrigger{ display: block; position: relative; padding-right: 45px; margin: 0; cursor: pointer;}
.tab_mobtrigger i{ position: absolute; width: 45px; height: 25px; line-height: 25px; right: 0; top: 0; bottom: 0; margin: auto 0; text-align: center;}
.tab_mobtrigger i:after{ content: "\f067"; font: normal normal normal 14px/1 FontAwesome; font-size: inherit;  text-rendering: auto; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;}
.tab_mobtrigger.active i:after{ content: "\f068";}
.tab_mobrow{ display: none; margin: 0px 0 0;}
.tab-content > .tab-pane.active > .tab_mobrow{ display: block; padding-bottom: 0; padding-top: 0px;}
/* Main Tab */
.main_tabsec .nav-tabs{ display: none;}
.main_tabsec .tab_mobtrigger{ background: #b69a6b; padding: 17.5px 45px 17.5px 15px; font-size: 16px;  }
.filter_tabsec .tab_mobtrigger{ padding: 5px 45px 8px 10px; font-size: 16px; }
.filter_tabsec .tab_mobtrigger:after{ 
content: ""; background: #ecdfcc; height: 3px; position: absolute; left: 0; right: 0; bottom: 0;  pointer-events: none; -webkit-transition: all 400ms; transition: all 400ms;}
.tab-content > .tab-pane.promation-tab-section.active > .tab_mobrow{padding-top: 0;}
.small_responsive_row .filter_tabsec .tab_mobtrigger.inner_tab_border{display: none;}
.small_responsive_row .tab-content > .tab-pane.active > .tab_mobrow{display: block;}
.main_tabsec .small_responsive_row  .nav-tabs{display: block; } 
.mobiCategory .nav-tabs{ display:none;}
.sub_tab_mobtrigger.active {background: #0b0405; color: #fff;}
.sub_tab_mobtrigger {background:#b69a6b; padding: 15px 45px 15px 15px; font-size: 16px;  color: #fff; display: block; position: relative; margin: 0; cursor: pointer;margin: 0px 15px;}
.sub_tab_mobrow{ display: none; margin: 10px 0px 0px;}
.tab-content > .tab-pane.active > .sub_tab_mobrow {display: block; padding-bottom: 0; padding-top: 10px;}
.sub_tab_mobtrigger i {position: absolute;width: 45px;height: 25px;line-height: 25px;right: 0;top: 0;bottom: 0;margin: auto 0;
text-align: center;}
.sub_tab_mobtrigger i:after {content: "\f067";font: normal normal normal 14px/1 FontAwesome;font-size: inherit;text-rendering: auto;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}
.sub_tab_mobtrigger.active i:after {content: "\f068";}
.myacc_filter.small_responsive_row .filter_tabsec .nav-tabs>li>a, .small_responsive_rewards .myacc_filter .filter_tabsec .nav-tabs>li>a{font-size: 15px;}    
.main_tabsec .small_responsive_rewards  .nav-tabs{display: block;}
.small_responsive_rewards .filter_tabsec .tab_mobtrigger{display: none;}    
.delivery-inner-comman{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.delivery-item{margin-top: 10px;}    
.delivery_total.delivery_total_number .delivery_total_left h4{font-size: 18px;}
.delivery_total.delivery_total_number .delivery_total_left h2{font-size: 14px;}
.delivery_total_left h3{font-size: 15px;}
.delivery_total_left span{line-height: normal;}
.redeem-item-hea .points{font-size: 15px;}
.pop-whole-lhs{padding: 80px 15px;position: static;-webkit-transform: translateY(-0%); transform: translateY(-0%); }
.pop-whole-lhs-inner{max-width: 100%;margin: 0 auto;}    
.signup-corparateone-popup .mfp-close, .signup-corparatetwo-popup .mfp-close, .guest-popup .mfp-close{right: 0px;background: #fff;opacity: 1;width: 30px;height: 30px;line-height: 30px;}
.signup-corparatetwo-popup .inside-popup-rhs, .guest-popup .inside-popup-rhs{padding: 50px 20px 15px 20px;}
.inside-popup-rhs{width: 100%;max-width: 100%;margin: 0 auto;float: none;}
.popup_equalrw{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.pop_title{text-align: center;padding-left: 0;margin: 0 auto 10px auto;}
.pop_title i{position: static;text-align: center;margin: auto;}
.pop_title h2{margin-top: 10px;}
.self_pop_item_loc p{padding: 0 10px; }
.product_chosen_item_left{width: 100%;margin: 10px 0;}
.product_chosen_item_left_second {width: 100%;margin: 0;}
.inn_product_row{margin-top: 20px;}
.inn-product-popup-bg, .inn-product-popup-background{background-image: inherit !important;padding-top: 0;text-align: center;}
.inn-product-popup-inner{max-width: 100%;}
.other-add-row li{width: 100%;}
.account_sec{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.accmenu_sec{width: 100%;}
.accsec_right{width: 100%; padding: 20px 15px;padding-bottom: 0px!important;}    
.filter_tabsec{
    margin: 0 0 20px;
}
.mobile-menu .mobile-menu-close span{
    margin-right: -25px;
}
.common-inner-blckdiv{
    margin-bottom: 0px;
}
.product_details_popup a.button.add_to_cart{
    margin: 10px 0 0;
}
.mainacc_menuout{margin: 0 0 10px; }
.mainacc_menuico{ display: block; position: relative; padding: 9px 0 9px 50px; color: #191919; font-size: 17px; cursor: pointer; overflow: hidden; z-index: 1;  }
.mainacc_menuico b{ font-weight: normal;}
.mainacc_menuico:after{content: ""; position: absolute; left: 0; top: -100%; width: 100%; height: 100%; background: #ecdfcd; -webkit-transition: all 0.5s; transition: all 0.5s; z-index: -1;}
.mainacc_menuico.active:after{ top: 0;}
.mainacc_menuico i{ position: absolute; left: 1px; top: 1px; font-size: 18px; width: 40px; height: 40px; line-height: 40px; background: #ecdfcd; text-align: center; -webkit-box-shadow: 0 0 1px  0 #555; box-shadow: 0 0 1px  0 #555;}
.mainacc_menuico i:before{ -webkit-transition: all 0.5s; transition: all 0.5s;	display: block;}
.mainacc_menuico.active i:before{ -webkit-transform: rotate(90deg); transform: rotate(90deg);}
.mainacc_menulist{ display: none;}
.mainacc_menulist > li{ display: block; width: auto; border-left: 0; text-align: left; border-top: 1px solid #ddd1c0;}
.mainacc_menulist > li >a{ text-transform: none; padding: 10px 15px 10px 50px;    background: #ecdfcd; color: #191919;}
.mainacc_menulist > li >a:after{ display: none;}
.mbacc_mslidersec{ display: block; padding: 0; position: relative;background: #fff;}
.mbacc_mslidersec .slick-track .slick-slide > div, .mobile-catering-slider .slick-track .slick-slide > div{ width: 100%; padding:0 1px}
.mbacc_mslide a, .mobile-catering-slider .slick-track .slick-slide a{padding: 10px 2px;
    position: relative;
    cursor: pointer;
    color: #c3c1c1;
    font-family: 'avantgarde_bk_btdemi';
    text-transform: capitalize;
    font-size: 17px;
    line-height: normal;
    display: block;
    height: 100%;
    text-align: center; }

.mbacc_mslidersec .slick-arrow, .mobile-catering-slider .slick-arrow{ background: rgba(0,0,0,0.6); border: 0; width: 30px; height: 30px; position: absolute; left: 2px; top: 0; bottom: 0; margin: auto 0; text-align: center; overflow: hidden; font-size: 0;color: #fff; z-index: 1;padding: 0}
.mbacc_mslidersec .slick-next{ right: 2px; left: auto;}
.mbacc_mslidersec .slick-arrow:before, .mobile-catering-slider .slick-arrow:before{ content: "\f104"; display: inline-block;
font: normal normal normal 14px/1 FontAwesome;font-size: inherit;text-rendering: auto;-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale; line-height: 25px; font-size: 24px; }
.mbacc_mslidersec .slick-next:before, .mobile-catering-slider .slick-next:before{ content: "\f105";}
.mbacc_mslidersec .slick-track {
    border-bottom: 4px solid #e7e6e6;
}    
.mbacc_mslide a:before {
    content: "";
    position: absolute;
    left: 51%;
    right: 51%;
    bottom: -4px;
    background: #8f0f33;
    height: 4px;
    transition-property: left, right;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}    
.mbacc_mslide.active a{color: #000;}   
.mbacc_mslide.active a:before{  left: 0;right: 0;}    
    
.main_tabsec_inner{ padding: 0px 0px;}
.mainacc_menusec .main_tabsec_inner .nav-tabs{ margin-bottom: 10px;}
.main_tabsec_inner > .tab-content > .tab-pane.active > .tab_mobrow{ padding-top: 0; margin-top: 0;}
.mainacc_moblink{ background: #b69a6b; padding: 17.5px 45px 17.5px 15px; font-size: 16px;  
color: #fff; display: block; position: relative; margin: 0 0 10px; cursor: pointer; display: block;}
.mainacc_moblink:hover, .mainacc_moblink:focus{ color: #fff;}
.mainacc_moblink i{ position: absolute; width: 45px; height: 25px; line-height: 25px; right: 0; top: 0; bottom: 0; margin: auto 0; text-align: center;}
.mainacc_moblink i:after{ content: "\f067"; font: normal normal normal 14px/1 FontAwesome; font-size: inherit;  text-rendering: auto; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;}
.mainacc_moblink.active i:after{ content: "\f068";}

.filter_tabsec .tab_mobtrigger.inner_tab_border{background: inherit;border: 0;color: #000;text-align: center;text-transform: uppercase;}
.myacc_filter{padding-bottom: 0;}
.main_tabsec_inner .nav-tabs{ display: block; border: 0;}
.main_tabsec_inner .tab_mobtrigger{ display: none;}
.main_tabsec_inner> .nav-tabs>li{ margin: 0; padding: 0 2.5px;}
.main_tabsec_inner> .nav-tabs>li> a{ text-align: center; font-size: 16px; height: auto; padding: 13px 15px; border-radius: 4px;}
.main_tabsec_inner> .nav-tabs>li> a > span > b{ display: none;}
.main_tabsec_inner> .nav-tabs>li > a > i{ display: none;}
.main_tabsec_inner> .nav-tabs>li > em { display: none;}
.acc-inform .form-group [class*="col-md-"]{ margin-bottom: 11px;}
.acc-inform .form-group [class*="col-md-"]:last-child{ margin-bottom: 0;}
.redeem_pro li h2{font-size: 30px;}
.redeem_pro li h3{font-size: 21px;}
.redeem_pro li{margin: 0 15px;}
.promation_btn li{width: 100%;margin-right: 0;margin-bottom: 5px;}
.promo-earned-content h4{font-size: 20px;}
.promo-earned-content h2{font-size: 26px;}
.promo-earned-content p{font-size: 15px;}
.abc_code span{font-size: 18px;}
.bg_top_slider h3{font-size: 17px;}
.banner-inner h5{font-size: 15px;}
.banner-inner span{font-size: 13px;}
.delivery-inner{padding: 20px 0;}
.tnk-delivery{padding: 15px;}
.chk-payment-btn-row .row{display: block;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.chk-payment-btn-row .con_first{width: 100%;-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.chk-payment-btn-row .go_second{width: 100%;-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.delevery_popup .btn_sec .row{display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.delevery_popup .con_first{width: 100%;-webkit-box-ordinal-group: 2;-ms-flex-order: 1;order: 1;}
.delevery_popup .go_second{width: 100%;-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2;}
.back_click{position: static;margin-bottom: 10px;}
/* Thank You */
.tnk-order h3{ font-size: 18px;}

/* Contact us */
.contactus_wrapper .row{ display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column;}
.contactus_wrapper .contact_left{ -webkit-box-ordinal-group: 3; -ms-flex-order: 2; order: 2;}
.contactus_wrapper .contact_right{ -webkit-box-ordinal-group: 2; -ms-flex-order: 1; order: 1; margin: 0 0 20px;}
.cms-page h3{ font-size: 22px;}
.award_galinfo h3{font-size: 17px}
/*promo*/
.promation_btn{ text-align: center; }
.promo-earned-col {width: 100%; display: block; margin: 0 0 15px; }    
.redeem_popsec .promo-earned-content{ width: 65%; }
.redeem_popsec .promo-earned-col-center .innerproduct-item-image{ width: 35%; }
.innersection_wrap {  padding: 20px 0 0 0;}
.count_num_t{display: none}    
.web_order_typecls li a{padding: 13px 10px}    
.web_order_typecls{margin-bottom: 30px}    
.promo-form h3 span{font-size: 16px}    
.promo-form h3{font-size: 24px}    
.mypromo-main-div .myacc_order_details{-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between}    
.mypromo-main-div .myacc_order_details .promo-earned-col{ width: 49%; margin: 0 0 20px 0 }    
    
.mobile-catering-slider{ display: block !important; padding: 0 40px 0; position: relative}  
.row-pgk-list > li, .pkg_gcol {  width: 50%;}     
.mobile-catering-slider button.slick-arrow{ opacity: 1; width: 40px; height: 52px }     
.mobile-catering-slider button.slick-prev { left: -40px;}
.mobile-catering-slider button.slick-next { right: -40px;left: inherit}    
.mobile-catering-slider .slick-track .slick-slide > div{ padding: 0 }    
.mobile-catering-slider .slick-track .slick-slide a{ padding: 10px }  

.reservation-full{padding: 25px 0px;}
.back_click{display: none}
.back_click_mbl {  position: absolute; display: block; top: 10px; left: 10px;} 
.back_click_mbl a{color: #fff;}    
.chk-payment .form-group.custom-tarms-div {  padding: 0 5px 20px;}    
.membership_status img{ max-height: 72px }    
.membership_status span{top: 18px}        
.setting_menu_list, .promo-earned-info:before{ display: none;}    
.accprofile{ padding: 20px 10px 20px;}    
footer { margin: 20px 0 0 0;}    
.promo-earned-col-image {  width: 100%;}    
.promo-earned-info { width: 100%; flex-flow: column wrap; padding: 20px 10px;}    
.home-banner .banner-info img { width: 60%;}    
    
}

@media (max-width: 980px){ 
.product-menu-listing .product-rhs{
    /*background: #fff;*/
    background: #FCF5D5;
    width: 100%;
    position: fixed;
    height: 100vh;
    right: 0;
    top: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
    z-index: 99;
    max-width: 620px;
    padding: 45px 0 55px 0;
    -moz-transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
    -ms-transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
    -o-transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
}
body.cart-items-open .product-menu-listing .product-rhs{
    -webkit-transform: translateX(0);
    transform: translateX(0);
    margin: 0;
    padding: 0 0 55px;
}    
.downalod-quote {display: none;}
.cart_row.grant-total-cls {margin-bottom: calc(25px + (100 - 25) * ((100vw - 300px) / (1600 - 300)));}
.product-menu-listing .product-rhs .hcart_dropdown{ overflow-y: auto;height: 100%;}    
.product-rhs > ul,
.product-rhs > ul li {height: 100%;}
.product-rhs .hcart_dropdown .cart_footer {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
}
.product-rhs .hcart_dropdown .cart_footer .cart_action {position: fixed;width: 100%;bottom: 0;left: 0;right: 0;padding: 10px;}
.product-menu-listing .product-rhs .hcart_dropdown .fixed_checkout { position: fixed; bottom: 0; left: 0; right: 0;}    
    .product-menu-listing .product-rhs .hcart_dropdown .fixed_checkout a{border-radius: 0}    
    
.product-rhs .product_orders_top{display: flex;position: relative;}    
.f-socail-top img { margin-left: 5px;width: 25px;}      
.product-lhs{width: 100%;padding-top: 10px}
.spm-lhs .search-rest, .spm-rhs .filter-area{font-size: 15px;}  
.spm-lhs { width: 300px;}    
.product-lo-top { min-width: 300px;}    
.product-lo-top a { padding: 15px 20px 12px; font-size: 14px;}    
.footer-nav-info .foot-row {justify-content: center;}
.footer-nav-info .foot-row .foot-sub-col-row {flex-flow: row;flex: 1 1 100%;max-width: 100%;justify-content: space-evenly;}
.footer-nav-info .foot-row .foot-col-social {flex: 1 1 100%;height: auto;margin-bottom: 20px;}
.footer-nav-info .foot-row .foot-col-add {flex: 1 1 280px;max-width: 280px;min-width: 280px;}
.footer-nav-info .foot-row .foot-total-col {flex: 1 1 440px;max-width: 440px;min-width: 350px;}
}
@media (max-width: 880px){ 
.product-lo-top { width: 100%; order: 1; margin-bottom: 15px;}
.search-product-main{order: 2; width: 100%; }    
.backto-rest {width: 48px;}    
.spm-lhs { width: calc(100% - 54px - 172px);}    
}

@media (max-width: 850px){    		
.hme-product-inner .title_sec{margin-bottom: 5px;}
.delivery_bg{ background-size: cover; }
.popup_ttsec, .news_postal_popup .popup_ttsec{width: 100%; padding: 30px 15px; height: auto;}
.popup_right{width: 100%; padding: 20px 15px;}
.news_postal_popup .popup_right{width: 100%; height: 300px;}
.awesome_del h2{margin-bottom: 0;}
.title_sec h2{font-size: 29px;}
.title_sec small{font-size: 14px;}
.hme-prt-slidecontent{padding: 10px 15px; top: 0; right: 0; bottom: 0; left: 0;}
.hme-prt-slidecontent-inner p{font-size: 13px;}

.tnk-header{max-width: 100%;}
.tnk-you .container{ padding-left: 0; padding-right: 0;}
.tnk-delivery .cart_footer.tnk_cart_footer{margin-top: 10px;}
.order-cat-table table{max-width: none;}
.ord-amount-item{max-width: 97%;}
.htopinfo p{font-size: 10px;}
.htopinfo p span{ font-size: 8px;}
.htopinfo{width: 100%; text-align: center;}
.htmenu_sec{width: 100%;text-align: center;}
.horder_btn .btn{width: 100%;}
.inn-product-popup{margin-top: 20px;}
.detail-pg-inner{padding: 50px 10px 0;}	
/*upadate package*/
.number-break .back_bx {left: 15px; width: 55px; height: 60px; font-size: 14px; padding: 40px 10px 10px;}
.number-break .back_bx:after {top: 10px; font-size: 26px;}
.number-break {padding: 15px 15px 15px 5px; margin: 20px 0px 30px;}
.number-break .break-time {	width: 100%; padding: 5px 10px; text-align: left; border-right: 1px solid transparent; margin-top: 70px;}
.number-break .break-time .title4 {font-size: 16px; margin: 0 0 10px;}
.number-break .making-catering {width: 100%; padding: 5px 10px; border-right: 1px solid transparent;}
.number-break .event-time {width: 100%; padding: 5px 10px;}
.tbars header{ height: 147px;}
.client_riv .client_riv_slider .slick-slider .slick-dots {display: flex !important;}
.client_riv .client_riv_slider .client_riv_item .client_riv_stitle {text-align: center;}
}

@media (max-width: 780px){
.home-outlets-list {padding: 40px 0 40px 0;}        
.date_list {padding: 20px 15px;}
.time_slot {padding: 20px 15px 20px;}
.add_time {margin: 10px 15px 20px 15px;}
.choose_location {padding: 20px 15px 20px; margin-bottom: 20px;}
.cedele-location {padding: 2px 15px 0px 0px;}
.location-list ul li {font-size: 14px; line-height: 1.3; height: auto; padding: 10px 15px;}
.catering_checout .checkout-total .cdd-details-lhs {padding: 0px 15px 20px 15px; margin: 20px 0 0 0;}
.catering .ttsplitter {margin: 0 auto 10px;}
.catering_checout .checkout-total .checkout-control-group-top {padding: 20px 0px 20px 0px;}
.checkout-total .checkout-control-group-middle {padding: 20px 0px 0px 0px;}
.catering_checout .checkout-total .cdd-details-rhs {padding: 0px 15px 20px 15px; margin: 20px 0 0 0;}
.event-time .title4 {font-size: 16px;}
.inner_banner {  height: 250px;}    
.myacc_order_details .current_order{width: 100%;    padding: 0 0 75px 0;} 
.load_more_div{
    padding: 0px 0 0 0;
}   
.web_order_typecls{ margin: 0 0 20px 0 }    
.order_delivery_item{ padding: 10px 20px 0 20px;}    

.checkout-total{margin: 0 auto}    
.hmenu-login-section {position: static;top: inherit; transform: none;}
.birthday-info-div{margin-bottom: 20px;     padding: 0px 5px 0px 5px;}    
.home-options-inner > ul{justify-content: center;}
.home-options-inner > ul > li{width: 48%; margin: 0 5px 15px;}
.hmenu_sec{display: none;}
.menu_icon.trigger_menunav_act{ display: block; width: 30px; height: 19px; margin: 3px 0px; padding: 0px 0px; cursor: pointer;}    
.menu_icon .icon-bar{ display: block; background-color: #fff; height: 2px; -webkit-transition: all 400ms; transition: all 400ms;}
.menu_icon .icon-bar+.icon-bar { margin-top: 6px; }    
 .logo img { 
     /* width: 110px; */
     width: 90px;
}    
.header-top-cls {padding: 30px 0;}    
.logo { max-width: 90px; position: absolute; left: 0; right: 0; margin: auto;}    
.logo-main-section { height: 68px;}    
.home-option-desc { padding: 20px 15px;font-size: 14px;  line-height: 1.4;}    
.home-option-desc h5 { margin-bottom: 10px; font-size: 16px;}    
.f-nav{display: none}    
.mobile-footer-nav { display: block; width: 100%;}    
.f-socail-top { margin-bottom: 20px;font-size: 14px;}    
.footer-nav-info .container-full{flex-wrap: wrap}    
.copyright-section { padding: 10px 10px;}    
.copyright-section p { font-size: 13px;}    
.menu-section-mobile-inner .slick-track .slick-slide > div .menu-title-link{font-size: 15px; display: flex; justify-content: center; align-items: center;} 
.accsec_right h1 { font-size: 30px;}
.accsec_right p { font-size: 16px;}    
.home-banner .btn-act-clss { padding: 10px 16px 10px; font-size: 14px;}    
.package_details{flex-wrap: wrap; }
.package_details_left { width: 100%;margin-bottom: 10px}
.package_details_right { width: 100%;}
.home-options-inner > ul > li:nth-child(even) .choose-option-hover{ left: inherit; right:100%;}     
.home-options-inner > ul > li:nth-child(3n) .choose-option-hover { left: 100%; right: inherit;}    
.pdr_right.price-fixed { top: 75px;}    
.abt-cms-lhs { width: 100%; font-size: 15px; order: 2;}
.abt-cms-rhs { width: 100%;order: 1; margin-bottom: 60px;}   
.client-sec {padding-bottom: 60px;}
.client-abt .client-abt-txt {flex: 1 1 100%;} 
.client-abt .client-abt-img {flex: 1 1 100%;padding-top: 60px;display: flex;justify-content: center;}
.client-abt .client-abt-img .client-img-1 {top: 0;max-width: calc(100% - 40vw);}
.client-abt .client-abt-img .client-img-2 {right: 55px;top: 0;max-width: calc(100% - 40vw);}
.client_riv .client_riv_slider .slick-slide.slick-active {padding: 0;}
}
@media (max-width: 767px){   
.acc-inform .form-group .row > .col-md-6 .row .col-md-6 {
        width: 100%;
}
.title1{font-size: 25px;}
.title3{ font-size: 18px;}
.htico_search{ position: static;}
.hsearch_sec{ left: 15px; right: 15px; width: auto;top: 50px;}
.banner .container, .delivery .container, .hme-product .container, .outlet .container{ padding-left:0; padding-right: 0; }

.mobile-login {}

.location-mapsec > div{ height: 350px;}
.main_tabsec .nav-tabs{ margin: 0 0 25px; border-width: 1px;}
.main_tabsec .nav-tabs>li>a{ font-size: 14px; padding: 10px 10px;}
.myacc_filter.small_responsive_row .filter_tabsec .nav-tabs>li>a:after{margin: 0 auto;top: 34px;}
.small_responsive_rewards .myacc_filter .filter_tabsec .nav-tabs>li>a:after{margin: 0 auto;top: 34px;}
.video_popup .youtube_player_iframe{ height: 300px;}
.selfpop_mapouter{ height: 280px !important;}
.chekout_cart_bar .cart_info{width: auto;}
.chekout_cart_bar .order-detail-inner-icon{margin: 0 auto;}
.redeem-col{padding: 15px 10px;}
.redeem-item-hea h4{font-size: 20px;}
.pop-whole-lhs{padding: 40px 15px;}
.inside-popup-rhs, .signup-popup .inside-popup-rhs, .signup-corparateone-popup .inside-popup-rhs{padding: 15px 20px;}
.signup-corparatetwo-popup .inside-popup-rhs{padding:50px 20px 15px 20px;}
.popup-header{padding: 10px 0 10px 0;}
.guest_chk_out{margin-left: 0;margin-right: 0;}
.guest_chk_out .btn{white-space: inherit;height: auto;}
.pop_title h2, .order-body h2{font-size: 25px;}
.pop_title small{font-size: 17px;}
.self_pop_row{ height: auto;}
.self_outlet{margin-top: 15px; height: auto;}	
.self_pop_col{width: 100%;	margin-bottom: 0;}
.self_pop_col.self_pop_col_right{width: 100%;margin-left: 0;max-width: 100%;}
.self_popup_map_col{ height: auto; margin: 0;}
.self_pop_item_map{width: 100%;	margin: 0 auto; display: none;}
.self_popup .self-back{ display: none;}
.self_outlet_inner{height: 330px;}
.self_pop_item_loc .or-seperator-row{display: none;}
.pouup_in{padding: 30px 20px 20px;}
.redeem_popsec .pouup_in{ padding: 30px 20px 20px;}
.redeem_popsec .title3{ font-size: 20px;}
.hme-product-inner .ttsplitter{margin-bottom: 0;}
.product_chosen_col{width: 100%;margin: 10px 0;}
.product_chosen_col_inner{margin-bottom: 15px;margin-left: 0;margin-right: 0;}
.accsec_right h1{font-size: 27px;}
.accsec_right p{font-size: 14px;}
.accsec_right .ttsplitter_md{margin-top: 20px;}
.redeem_pro li.pro-subscribe button{font-size: 16px;}
.outlet_inner{padding: 23px 20px 20px 20px;}
.breadcrumb_left{width: 100%;display: inline-block;}
.breadcrumb_right{width: 100%;float: none;display: inline-block;margin: 10px 0;}
.breadcrumb_right .chosen_left{ left: 0; right: auto;}
.breadcrumb_right .re_select{max-width: calc(100% - 73px);}
.bakery_inner h3{font-size: 23px;}
label{font-size: 14px;}
.process_right p{font-size: 15px;}
.product_tag{float: none;}
.product_chosen_col .text-box{margin-left: 0;}


/*----Venue Details page----*/	
.myacc_hea h3 {font-size: 20px;}
.ttsplitter span {font-size: 14px;}
.choose_location h4 {font-size: 14px;}
.choose_location .custom_radio span {font-size: 14px; line-height: 30px;}
.choose_location .custom_radio span:before {width: 26px; height: 26px;}
.cedele-location .form-group {margin: 20px 0 20px;}
.date_list h3 {font-size: 14px;}
.res-datetime-picker ul li dl dd, .catering_days_ul li dl dd {font-size: 17px;}  
.res-datetime-picker ul li dl.time_inner dd {font-size: 20px;}
.res-datetime-picker ul li dl dt {font-size: 12px;line-height: 1;}
.res-datetime-picker ul li dl.cl-next-days span {font-size: 14px;}
.res-datetime-picker ul li dl.cl-next-days {line-height: 1;}
.time_slot .time {padding: 0px 0px 10px 0px;}
.add_time {margin: 10px 15px 10px 15px;}
.time_slot .order-btn {padding: 20px 0px 15px;}
.time_slot h4 {font-size: 14px;}
.time_slot .order-btn .no {height: 42px; font-size: 20px; padding: 7px 50px; width: 49%;}
.time_slot .order-btn .yes {height: 42px; font-size: 20px; padding: 7px 50px; width: 49%;}
.time_slot .add_time .label {float: none; font-size: 14px; display: block; width: auto; text-align: center}
.time_slot .time label {font-size: 14px;}
.location-list ul li {font-size: 13px;}
/*----Checkout page----*/
.order_details .panel-title>a {font-size: 14px;}
.order_details .panel-body {padding:15px 10px;}
.package .package_cnt {padding: 0px 0px 15px;}
.package .package-list {padding: 0px 0px 10px 0px; color: #000000;}
.checkout_time {padding: 0px 0px 10px;}
.checkout_time label {font-size: 14px;} 
.package .title {font-size: 14px;}
.package .package-list .one {font-size: 14px;}
.package .package-list .two {font-size: 14px;}
.package .qty_bx {margin-top: 10px;}    
   
.package .amt {margin-top: 15px;}
.amt span {font-size: 14px;}
.addon {padding: 15px 0px 15px 0px;}
.addon h4 {font-size: 14px;}
.addon_list h5 {margin: 10px 0px 15px 0px;}
.addon_list h5 .title {font-size: 14px;}
.addon_list p {margin: 0px 0px 0px 0px; font-size: 14px;}
.total_amt {padding: 15px 15px 10px 15px;}
.total_amt .total {font-size: 20px;}
.total_amt .subtotal {font-size: 14px;}
.buffet_setup {padding: 10px 0px 15px;}
.buffet_setup h5 .title {font-size: 14px;}
.buffet_setup p {font-size: 14px; margin: 0px 0px 7px 0px;}
.download-quote .btn {font-size: 14px;height: 30px;}
.catering_checout .redeem-item-hea h4 {font-size: 18px;}
.catering_checout .redeem-item-hea span {font-size: 14px;}
.catering_checout .redeem-item-hea .points {font-size: 13px;}
.catering_checout .redeem_apply .form-control {height: 40px;}
.catering_checout .focus-out .btn_minwid {min-width: 100px; padding-left: 15px; padding-right: 15px;}
.catering_checout .focus-out .btn {font-size: 14px; height: 40px;}
/*-----Thank You page------*/
.catering_thak_you .package .title {padding-left: 0px;}
.catering_thak_you .order_details .qtybxs {font-size: 14px;line-height: 1.6;}
.catering_thak_you .package .amt {margin-top: 0px;}
.catering_thak_you .addon_list h5 .title {padding-left: 0px;}
.catering_thak_you .addon_list h5 {margin: 10px 0px 5px 0px;}
/*-----Categories------*/
.number-break .back_bx {left: 15px; width: 55px; height: 60px; font-size: 14px; padding: 40px 10px 10px;}
.number-break .back_bx:after {top: 10px; font-size: 26px;}
.number-break {padding: 15px 15px 15px 5px; margin: 20px 0px 30px;}
.number-break .break-time {	width: 100%; padding: 5px 10px; text-align: left; border-right: 1px solid transparent; margin-top: 70px;}
.number-break .break-time .title4 {font-size: 15px; margin: 0px 0px 10px;}
.number-break .making-catering {width: 100%; padding: 5px 10px; border-right: 1px solid transparent;}
.number-break .event-time {width: 100%; padding: 5px 10px;}
.event-time .form-control { height: 45px;}
.making-place {font-size: 14px;}
.event-time .title4 {font-size: 15px;margin-bottom: 7px;}
.title4 {font-size: 14px;}
.bts.text-right {text-align: left;}
.pkselected_filtersec label {font-size: 14px;}
.pkg_gimg img{width:100%;}
.pkg_ginfo h4 {font-size: 16px;margin: 0 0 10px;}
.pkgselect_dd .chosen-container {font-size: 14px;}
.pkgselect_dd .chosen-container-single .chosen-single {font-size: 14px;}
.pkg_gbtsec .btn {font-size: 14px; min-width: 170px;height: 42px;}
.pkg_ginfo .btn {font-size: 14px; padding: 9px 20px 7px;height: 34px;}	
/*Addon*/
.pkgaddon_checklist li {width: 100%; padding: 0px 3px;}
.pkgaddon_checklist .custom_checkbox span {padding: 15px 15px 15px 70px; font-size: 16px;}
/*SETUP*/
.package_controltt .custom_radio span {font-size: 14px;}
.pkgsetup_list .package_formchecklist {padding-left: 0px;margin: 20px 0px 0px;}
.pkgsetup_list .package_formchecklist .custom_checkbox span {font-size: 14px;}
.package_form_helptext {font-size: 14px;}
.package_formchecklist .custom_checkbox span b {font-size: 14px;line-height: 1.5;}
.pkselected_package{margin-bottom: 10px;}	
.pkglist_pricebx > b {font-size: 16px;}
.pkselected_package .chosen-container-single .chosen-single {height: 42px;line-height: 42px;}
/*promo*/
.promation_btn .btn {font-size: 14px; height: 35px; padding: 7px 10px; min-width: 100px; }
.prodet_bansec{ display: block;}
.back_arrow{ width: 43px; height: 43px; line-height: 43px; }
.back_arrow img{ max-width: 11px;}
.prodet_banleft{width: auto; padding: 15px 15px 10px 15px;}
.prodet_baninfo{width: auto;}
.product_modifiers_listinner .modifiers_main_list{ width: 100%;}
.package_checkbx .package_formchecklist .custom_checkbox span{ font-size: 16px}    
.pkglist_img img{ width: 100% }
.package_checkrow {margin-bottom: 0px;}    
.pkg-detail-top-row{ -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-flow: wrap column; flex-flow: wrap column; -webkit-box-align: center; -ms-flex-align: center; align-items: center;}    
.pkselected_filtersec .form-control{ padding-right: 46px }    
.datelist_bracktime h3, h3.choose-eventtype { font-size: 20px;margin: 0 0 10px 0;}    
.pkselected_catgrytext {  text-align: center; margin: 10px 0;}    
.checkout-total .checkout-control-group-bottom textarea{height: 110px}    
footer{ margin: 10px 0 0 0 }    
#CartListMdl .package .package_cnt .col-sm-cls .hr-flt-cls{font-size: 16px}    
#CartListMdl .Cart-list-mdlheader h2, #PackageEditMdl .Cart-list-mdlheader h2 {font-size: 26px; margin: 5px 0 10px 0;}    
.locate-lirow{ display: block }    
.mfp-close-btn-in .order_popup .mfp-close, .mfp-close-btn-in .awesome_popup .mfp-close, .mfp-close-btn-in .self_popup .mfp-close, .mfp-close-btn-in .delevery_popup .mfp-close, .mfp-close-btn-in .error_postal_popup .mfp-close, .mfp-close-btn-in .receipt_popup .mfp-close, .mfp-close-btn-in .receipt_cateringpopup .mfp-close, .changepw_popup .mfp-close, .redeem_popsec .mfp-close, .pay-conf-popup .mfp-close, .warning_popup .mfp-close, .venue_popup .close-icon-but{ top: 9px; right: 10px; }    
.guest-checkout-popupdiv{margin: 0 -20px;padding: 25px 25px;}    
.login-popup .inside-popup-rhs{padding-bottom: 0}    
.productlist-main-div .products-list-ulmain{justify-content: space-between;}    
.productlist-main-div .products-list-ulmain > li{width: 48.5%; margin: 0 0 20px 0;}    
.chk-payment-title h3, .chk-addonpro-title h3 { font-size: 19px;}    
.chk-payment { padding: 10px 0px 30px 0px;}    
.mainacc_toptext { padding:0px 0 5px 0;}    
 .products_sidebar_inner ul li a{font-size: 14px;    line-height: 1.2;}    
 .footer-nav-info .foot-row .foot-col-link {flex: 1 1 140px;max-width: 140px;margin: 0 0 20px;}
 .footer-nav-info .container-full {padding: 0 15px;max-width: 100%;}
}
@media (max-width: 680px){ 
.delivery_bg{ width: 100%;}
.innerproduct-col{width: 100%; margin: 0 0 15px;}
.cart_body{padding: 10px;}
.cart_left{width: 100% !important; text-align: left;}    
.tnk-delivery .cart_row{padding-top: 5px;}
.cart-header-first .cart_right{margin: 20px 0 0 0; text-align: left; }    
.menu_icon{margin-right: 0;}    
.delivery-inner{ padding: 0;}
.delivery-item{	width: 100%; max-width: 100%; padding: 20px 15px 16px;  margin-top: 0; border: 0;border-bottom: 1px solid #f0e8dd;}
.delivery-item:last-of-type{border-bottom: 0;}
.hme-prt-row .slick-slider, .detail-pg-slider .slick-slider{padding: 0 0px;}
.hme-product-inner .slick-prev, .hme-product-inner .slick-next{z-index: 1; background: #f5f5f5;}
.detail-pg-slider-comman .slick-prev, .detail-pg-slider-comman .slick-next{	z-index: 1;background: #f5f5f5; }
.inn-product-popup-inner{max-width: 100%;}
.accpoints_bxin{padding: 20px 13px 55px;}
.accearn_pointtop span{font-size: 35px;}
.accsec_right .ttsplitter span{z-index: 1;}
.ord-amount .row{width: 100%;}
.ord-rec-sec li{width: 100%; max-width: 100%;}
.ord-amount [class*='col-']{padding-right: 0;}
.ord-amount{padding: 10px 0 0 10px;}
.order-cat-table{padding: 0 10px;}   
.order-cat-hea{max-width: 100%; padding: 8px 10px; margin-top: 10px;}
.ord-amount h2{font-size: 22px;}
.order-cat h2{font-size: 18px;}
.ord-amount-item{max-width: 100%;}
.cur-ord-rec{float: none;  padding: 0 10px;   width: 100%;  max-width: 100%; }
.cur-order-del{ max-width: none; padding: 0 10px; }
.cur-ord-rec li{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;}
.click_view{text-align: center; margin: auto; padding: 0 10px; left: 0; right: 0;}
.cur-order-del li{margin-bottom: 10px; width: 100%; padding-left: 0; font-size: 19px;}
.cur-order-del li.cur-order-del-time{ width: 45%; text-align: right; margin-bottom: 0;}
.cur-order-del li.cur-order-del-date{ width: 55%; margin-bottom: 0;}
.cur-order-del li a.btn{ display: block;}
.cur-ord-rec-desktop, .click_view_desktop{ display: none;}
.cur-ord-rec-mobile{ position: relative; padding: 0 0 10px; border-bottom: 1px solid #efefee; margin: 10px 0;}
.myacc_dropmenu{position: absolute; width: 100%;left: 0;top: 100%; padding: 15px;}
.myacc_dropmenu::after{top: -16px; -webkit-transform: translateY(-50%); transform: translateY(-50%); left: 33px; -webkit-transform: rotate(448deg); transform: rotate(448deg);}
.check-pg-slider .slick-slider{padding: 0;}
.check_pg_inner .slick-prev, .check_pg_inner .slick-next{background: #f5f5f5;}
.banner-text-box-left{width: 100%; margin-bottom: 10px;}
.banner-inner h5{ height: auto;}
.banner-inner span{ height: auto;}
.banner-text-box-right{width: 100%;}
.banner-text-box-right .btn_minwid{margin-bottom: 0;}
.banner-text-box{padding: 20px 18px 20px 20px;}
.banner-right-content .btn{min-width: 100%;}    
.checkout-total .checkout-control-group-bottom textarea{height: 100px;}
.web_order_typecls li a{  padding: 15px 5px;}    
.promo-earned-info{     -webkit-box-orient: vertical;     -webkit-box-direction: normal;     -ms-flex-flow: column;     flex-flow: column; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center; align-items: center;text-align: center}    .promation_btn .button {margin: 5px 5px 0 0;padding: 10px 22px 8px;font-size: 14px;}    
.product-details .row .cart_left{width: 100%; padding: 0}    
.product-details .row .cart_right{width: 100%; padding: 0; -webkit-box-pack: end; -ms-flex-pack: end; justify-content: flex-end; -webkit-box-align: center; -ms-flex-align: center; align-items: center }    
.product-details .row .cart_right .cart_price{ margin: 0 0 0 10px }    
.function-rooms ul li{ width: 49% }    
.function-rooms ul li:nth-child(3n){ margin-right:2% }    
.function-rooms ul li:nth-child(2n){ margin-right: 0 }    
a.view-rooms img{ min-height: 190px }    
.birthday-info-top h3 img{ width: 26px}
.birthday-info-top h3{font-size: 24px}    
.checkout-body-section { padding: 10px;}    
.checkout-right-body-section { padding:10px 10px 10px 10px;}    
.ourrest_info h4 { font-size: 18px;}   
.home-top-about .about-icon {flex: 1 1 100%;}
.home-top-about .about-txt {flex: 1 1 100%;}
.home-top-about .about-txt h1 {display: none;} 
.home-top-about .about-txt h4 {margin: 15px 0;}
}
@media (max-width: 640px) {
    .footer-nav-info .foot-row .foot-sub-col-row {flex-flow: row wrap;justify-content: center;}
    .footer-nav-info .foot-row .foot-col-add {margin-bottom: 20px;}
}
@media (max-width: 620px) {
    .product-menu-listing .product-rhs {max-width: 100vw;}
    .client-abt .client-abt-img .client-img-1,
    .client-abt .client-abt-img .client-img-2 {max-width: calc(100% - 50vw);}
}
@media (max-width: 580px){ 
.Products .breadcrumb{ padding: 64px 0 0 0;}
.Products .bread_space { margin: 64px 0 0; }
.htico_cart,
.htico_search { padding-right: 3px;}	
.mobile_account_item > a{ font-size: 15px;padding: 14px 40px 10px 40px;}
.mobile_account_item > a > i{ width: 20px; height: 20px; }	
.mobile_mainacc_menutrigger, .mobile_account_menu_click i.arrow_myaccount{ background-size: 16px auto; width: 45px; height: 49px;}
.mobile_account_menu_click{ font-size: 17px; padding: 12px 45px 12px 15px;}
.mobile_account_delivery_item a, .mobile_account_item_login > a, .mobile_mainacc_menulist li a{  font-size: 17px;}
.mobile_account_item a.myacunt_act{font-size: 17px;padding: 12px 45px 12px 15px;}
.loc-cont-part{ display: none;}
.bakery_row_fixed{ top: 150px;}  
.full-login-new-body {padding: 30px 10px;}
.tbars .bakery_row_fixed{ top: 184px;}
.footer-inner [class*='col-']{width: 100%;margin-bottom: 10px;}
.footer-inner h4{margin-bottom: 10px;}
.chk-payment-col-radio li{margin: 0 0 10px 0;width: 100%;max-width: 95%;}
.chk-payment-col-radio li:last-child{margin-bottom: 0;}
.self-back{top: 5px;font-size: 16px;}
.pop-whole-lhs{padding: 40px 15px 20px;}
.pop-whole-lhs h3, .signup-popup .pop-whole-lhs h3{font-size: 30px;}
.pop-whole-lhs p{font-size: 16px;}
.order_delivery_item li{max-width: 95%;}
.order_delivery_item li:last-child{margin-bottom: 0;}
.order-body{padding: 30px 0;}
.video_popup .order-body{padding: 50px 30px 25px 30px;}
.self_outlet h2{font-size: 21px;}
.self_outlet_inner li a{font-size: 14px;}
.self_pop_item_map{width: 100%;}
.self_pop_item_map img{width: 100%;}
.self_pop_item_loc .loc_btn{height: 52px;font-size: 14px;min-width: 100%;}
.self_pop_item_loc p{font-size: 15px;}
.chosen_adn_left span, .product_chosen_col_right .custom_checkbox span{font-size: 14px;}
.inn_product_hea p{margin-bottom: 10px;font-size: 13px;}
.prd_chosen_row{padding: 20px 10px 15px 10px;}
.prd_chosen_sub_item_left{width: 100%;margin: 5px 0;}
.prd_chosen_sub_item_right{width: 100%;}
.prd_chosen_sub_item_right button{max-width: 100%;}
.footer-inner .news-letter button{padding: 15px 12px;font-size: 14px;}
.footer-inner h4{font-size: 17px;}
.footer-inner ul li a{font-size: 14px;}
.earned_points li h2{font-size: 20px;}
.tag span{font-size: 12px;}
.htmenu_sec > li{padding: 0 15px; }
.megaimg_item{width: 100%;  max-width: 364px;}
.pkselect_tabsec > .nav-tabs>li>a {font-size: 15px; padding: 10px 10px;}
.bts .form-group {width: 100% !important;}
.bts label {margin: 5px 0px 10px; display: block;}
.pkselected_filtersec label { display: block;  width: 100% !important; margin:5px 0px 0px;padding: 0; text-align: center}
.pkselected_package .form-group { width: 100%;} 
.pkselected_filtersec .time-label {display: block;  width: 100% !important;text-align: left; margin:5px 0px 5px;}
.pkselected_break .form-group {width: 100%;} 
.chk-payment-btn .btn {padding: 10px 22px; height: 37px;font-size: 14px;}
.chk-payment-btn .btn_download {font-size: 14px; padding: 10px 20px 10px;height: 35px;} 
.inner_banner {    height: 200px;}
.mbacc_mslide a{ padding: 15px 10px;    font-size: 16px}
.chk_hea > span{display: block; float: none; clear: both; margin: 5px 0 0 0}  
.upkg_tabsec .chk-payment-btn .chk-payment-btn-row a{font-size: 17px}
    span.myaccount_separate{height: 14px}
/*21-5-19*/
.reservation-summary ul li span{font-size: 13px;}
.reservation-summary{padding: 15px;}
.reservation-summary-full{background: none; padding-top: 0px; padding-bottom: 0px;} 
.reservation-full{padding: 20px 0px;}   
.reservation-full h2{margin-bottom: 15px; font-size: 30px;}
.reservation-summary-full h3{font-size: 28px; margin-bottom: 15px;}
.go-back a, .continue a{font-size: 17px;}
.reservation-summary-button{max-width: 425px; margin-left: auto; margin-right: auto;}
/*.reservation-counter, .choose-outlet, .choose-datetime-full, .special-instructions{background-color: transparent; padding-top: 0px;}*/
.reservation-counter-full{display: block;}
.reservation-counter-left, .reservation-counter-right{float: none; width: 100%;}
.reservation-counter-single:first-child{margin-top: 30px; margin-bottom: 30px;}
.goto-chkpage-cls a, .viewmenu-btn-row a{ width: 100%; margin: 0}
.goto-chkpage-cls a:first-child, .viewmenu-btn-row a:first-child{ margin-bottom: 5px }    
.popup_ttsec, .news_postal_popup .popup_ttsec{ min-height: inherit}    
.two-button-row{-ms-flex-wrap: wrap;flex-wrap: wrap}    
.delevery_popup .go_second{margin-top: 5px}  
.products_sidebar_inner .no-slide-res{display: none}    
.products_sidebar_inner .slick-slider{ display: block;padding: 0 30px; }
    
.reservation-summary-button .go-back, .reservation-summary-button .continue{width: 100%;} 
.reservation-summary-button .continue{margin-bottom: 10px; margin-top: 5px;}
.reservation-summary-button{display: -webkit-box;display: -ms-flexbox;display: flex; -ms-flex-wrap: wrap-reverse; flex-wrap: wrap-reverse;}
.common-top-div.productpage-main-div {  padding-top: 155px!important;}    
.left-input-div, .right-input-div {max-width: 48.5%;}    
.subtle{display: none}    
.home-options-tab ul li {font-size: 15px;}    
.home-option-desc { padding: 15px 15px; font-size: 14px; line-height: 1.4;}    
.redeem-item-hea .points { font-size: 15px; position: relative; width: 100%; max-width: inherit; top: inherit; margin: 7px 0;}    
.redeem-item-hea-inner { width: 100%;}
.checkout-heading { padding: 15px 10px 0px;}
.cart_row.tnkorder-first > div h5 { font-size: 14px;} 
.cart_row.tnkorder-first > div p { font-size: 13px;}    
.congrats-inner { padding: 30px 15px 20px;}    
.mobile-order-nowbtn a { padding: 13px 15px;font-size: 15px}      
.choose-option-hover{display: none}    
.mobile-choose-option-hover{display: block} 
 .home-options-inner > ul > li{ width: 100%; margin: 0 0 15px 0 }      
.home-options-inner > ul > li:before, .home-options-inner > ul > li:after{display: none}    
.mobile-choose-option-hover ul li ul{padding: 5px 0}    
.mobile-choose-option-hover ul li ul li a{font-size: 15px}    
.pdr_right.price-fixed { top: 75px; left: 0 !important;max-width: 350px;margin: 0 auto;right: 0;}  
.fix-only-lhs h3{text-align: left; margin-bottom: 10px; font-size: 15px;}    
.pdr_right.price-fixed h4 { font-size: 16px;}    
.abt-cms-lhs h2 { font-size: 35px;}    
.abt-cms-lhs { font-size: 14px;}    
.outletul>li {  width: 100%; margin: 0 0 30px;}    
}
@media (max-width: 550px){    
.bg_top_slider h3{ font-size: 12px;}
.bg_top_slide p { font-size: 11px; }
.promo-earned-col-center{ display: block; }
.promo-earned-col-center .innerproduct-item-image{ display: block; width: 100%; height: 200px; }
.promo-earned-content{ display: block; width: 100%; }
.promo-earned-content p {font-size: 13px; }
.redeem_popsec .promo-earned-col-center .innerproduct-item-image{ display: block; width: 100%; height: 200px; }
.redeem_popsec .promo-earned-content{ width: 100%; display: block; }
.redeem_popsec .promo-earned-content h4 {font-size: 20px; }
.redeem_popsec .promo-earned-content h2, .redeem_popsec .promo-earned-content h2 span{font-size: 25px; max-height: inherit; overflow: visible;}
.promo-earned-col-center .innerproduct-item-image {display: block; width: 100%; height: 250px; }
.award_galcol{width: 100%; padding: 0}    
.award_galcol:last-of-type{ margin-bottom: 0 }    
.award_galinfo{margin: 0;    top: 70px;}
.featured-products-main h2, .serving-passionately-right h3{font-size: 28px}
.hmenu_list{ width: 200%; max-width: inherit}
.mypromo-main-div .myacc_order_details .promo-earned-col { width: 100%;  margin: 0 0 15px 0;}    
.filter_tabsec .nav-tabs>li>a:after{max-width: 50%}    
.hook{margin-bottom: 30px} 
.choose-outlet, .special-instructions, .choose-datetime-full{padding-left: 10px; padding-right: 10px;}
.featured-products-main > ul > li{width: 100%; margin: 0 0 20px 0}    
.tnk-chk-order .button { padding: 15px 10px 15px; }     
.productlist-main-div .products-list-ulmain > li, .product_det_cards > ul li.det_card{width: 100%; margin: 0 0 20px 0;}    
.tnk-order { padding: 20px 15px;}    
}

@media (max-width: 480px){ 
.cdd-details-rhs .event_timing .form-group .input_field .custom_select {width: auto;}
.acc-inform .form-group .row > .col-md-6 {
    width: 100%;
}
.menu_icon{ width: 30px;}
.innerproduct-row{-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center; text-align: center;}
.innerproduct-item-text{margin-bottom: 15px;}
.spilter{text-align: center; margin: 5px auto; vertical-align: middle;}
.two-links a{ margin: 0 2px; vertical-align: middle;}
.popup-body .sign_reg a{white-space: inherit; height: auto; font-size: 14px;}
.news_subscribe p{font-size: 14px;}
.pop_title h2{font-size: 26px;}
.news_postal_popup .pop_title p{font-size: 14px; line-height: normal;}
.title1{font-size: 20px;}
.inn_product_hea_left h3{font-size: 18px;}
.text-box textarea{font-size: 14px;}
.popup_social_icon{width: 100%; padding-left: 0;}
.popup_social_icon li a{padding: 0 8px;}
.popup_social span{color: #fff;}
.order-cat-table h4{font-size: 14px;}
.order-cat-table table td{font-size: 14px;}
.main_tabsec_inner> .nav-tabs>li> a{ font-size: 12px;}
.delivery_time [class*='col-']{width: 100%;}
.chosen_adn_left{width: 280px;}
.delivery_total.delivery_total_number{padding-bottom: 0;}
.redeem_apply .btn{ min-width: auto; padding-left: 10px; padding-right: 10px;}
.redeem_apply label{ font-size: 12px; top: 17px;}
.redeem_apply.focused label{ top: -7px;}
.redeem_popsec .btn{ height: 46px; }  
.order_delivery_item li .header-ordernow-single-img img { height: 50px;}    
.order_delivery_item h3{font-size: 14px}    
.footer-bottom-left{ width: 100%; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-flow: column wrap; flex-flow: column wrap; -webkit-box-align: center; -ms-flex-align: center; align-items: center;  margin: 10px 0 10px 0;}    
.footer-bottom-right{width: 100%;    display: -webkit-box;    display: -ms-flexbox;    display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center;}    
.footer-bottom-right ul{padding: 0}    
.footer-bottom-right h3{margin: 0 5px 0 0}    
.featured-products-section{padding-bottom: 20px}       
.catering_days_ul li dl dd, .catering_days_ul li dl.time_inner dd {font-size: 14px;}    
.catering_days_ul li dl dt{font-size: 10px}    
.catering_days_ul li dl.cl-next-days span{font-size: 11px}    
.event-type-maindiv.two-button-row a {  font-size: 16px; padding: 15px 10px;}
.ownvenue-inner-div{max-width: inherit; margin: 20px auto 0}   
.order-select-brktime ul .time-cls{-ms-flex-wrap: wrap;flex-wrap: wrap; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center}    
.order-select-brktime ul .time-cls label{width: 100%; text-align: center; margin: 0 0 5px 0}    
.more-time-slots{display: -webkit-box;display: -ms-flexbox;display: flex}    
.more-time-slots a{ -webkit-box-flex: 1; -ms-flex: 1; flex: 1; padding: 10px 10px 10px; font-size: 16px}    
.checkout-total .form-group.controls-three > div{ width: 100%; margin: 0 0 10px 0 }    
.checkout-total .form-group.controls-three > div:last-of-type{ margin-bottom: 0 }    
.catering_checout .checkout-total .checkout-control-group-top { padding: 10px 0px 20px 0px;}    
.checkout-total .checkout-control-group-middle .form-group .input_area{ width: 100%;margin-bottom: 10px}    
.chk-payment-btn-row.checkout-continue-cls .row-replace, #PackageEditMdl .chk-payment-btn-row .row-replace{-ms-flex-wrap: wrap;flex-wrap: wrap} 
.chk-payment-btn-row.checkout-continue-cls .row-replace .col-md-cls, #PackageEditMdl .chk-payment-btn-row .row-replace .col-md-cls{ width: 100%} 
.chk-payment-btn-row.checkout-continue-cls .row-replace .go_second{ margin-top: 5px }    
.chk-payment-btn-row.checkout-continue-cls .row-replace .button{ font-size: 18px; padding: 14px 10px;}
#PackageEditMdl .chk-payment-btn-row .row-replace .col-md-cls:first-of-type{-webkit-box-ordinal-group: 3;-ms-flex-order: 2;order: 2; margin-top: 5px}   
.catering_days_ul .tag{width: 100%; left:auto; height: 40px; top: 13px;}
.phone-show-dskp{ display: none;}
.phone-show-mbl{display: inline-block}    
.locate-lirow .phone-show-mbl a{ color: #000 }    
.locate-lirow.active .phone-show-mbl a{ color: #fff }  

.backto-rest {margin-bottom: 5px;}    
.spm-lhs {width: calc(100% - 54px); margin-bottom: 5px;}   
.spm-rhs {width: 100%; margin-left: 0;}   
.product-menu-listing { padding: 10px 0 10px 0;}  
.search-product{padding-top: 20px}  
.common-filter ul { justify-content: space-between;}    
.common-filter ul li { width: 49%;    margin: 0 0 10px;font-size: 14px;}    
.mainacc_toptext p { font-size: 15px;}      
.package_details_right_inner{flex-wrap: wrap}    
.pdr_left{width: 100%}    
.pdr_right{width: 100%; margin-top: 15px}
.pdr_right h4 { margin-bottom: 10px;font-size: 20px;}    
.mobile-menu-section-left .slick-track .slick-slide > div .menu-title-link{font-size: 15px} 
.product-lhs .product-edit-half a{  font-size: 15px  !important;padding: 12px 10px 12px !important;}    
    .product_acc_grp .accordion .accordion_title span{font-size: 11px}    
} 

@media (max-width: 420px){
.hcart_tt{ padding-left: 20px;}
.hcart_tt h3{ font-size: 16px;}
.hclear_cart{ font-size: 12px;}
.location-list ul li {font-size: 12px;}
.cedele-location P {font-size: 12px;}
.catering_checout .checkout-total .cdd-details-rhs { padding: 30px 10px 25px 10px;}
.res-datetime-picker .tag {left: -7px;width: 125%;}
.number-break .back_bx {left: 10px; width: 42px; height: 42px; font-size: 12px; padding: 26px 6px 6px;}
.number-break .back_bx:after {top: 6px;font-size: 16px;}
.number-break .break-time {margin-top: 50px;}
.pkselect_tabsec > .nav-tabs>li>a {font-size: 13px; padding: 7px 7px;}
.title4 {font-size: 14px;}
.pkglist_infott h3 {font-size: 16px;}
.pkglist_infott p{font-size: 13px;}
.package_checkbx .package_formchecklist .custom_checkbox span {font-size: 13px;}
.package_checkbx .package_formchecklist .custom_checkbox span b {font-size: 13px;}
.pkglist_info p{font-size: 13px;}
.main_tabsec .nav-tabs>li>a { font-size: 13px; padding: 5px 6px;}        
.function-rooms ul li{ width: 100%; margin:0 0 10px 0 }    
.function-rooms ul li:nth-child(3n){ margin-right:0% }    
.function-rooms ul li:nth-child(2n){ margin-right: 0 }        
.function-rooms ul li:last-child{ margin-bottom: 0 }    
.function-rooms ul li a{height: auto}    
a.view-rooms img{min-height: inherit; max-height: inherit; height: auto}    
.catering_days_ul li dl.cl-next-days span {font-size: 10px;}    
.catering_days_ul li dl dt { font-size: 9px;}    
.catering_days_ul li dl dd, .catering_days_ul li dl.time_inner dd {font-size: 12px;}    
#PackageEditMdl .pkselected_filtersec .row-replace{-webkit-box-orient: vertical;-webkit-box-direction: normal;-ms-flex-flow: column;flex-flow: column} .catering_days_ul .tag{width: 100%; left:auto; height: 40px; top: 13px;}
.hcart_round { top: -8px; }
 .product-lhs-inner .products_list { padding: 10px 12px 20px 12px;} 
.myacc_head_sec {flex-direction: column;-ms-flex-direction: column;}
.myacc_head_sec .head_right::before {display: none;}
.myacc_head_sec .head_right {background: #6e1d24;text-align: center;padding: 5px;}
}

@media (max-width: 380px){
.delivery_total_text::before{display: none}    
.tbars header{ height: 154px;}
.mobile_mainacc_menutrigger{ background-size: 12px auto; width: 32px;}
.mobile_account_item > a{padding: 14px 25px 10px 40px;  font-size: 14px;}
.mobile_account_item > a > i{ left: 5px; width: 22px; height: 22px;}	
.mobile_mainacc_menulist li a{ padding: 12px 5px 12px 35px; font-size: 14px;}
.mobile_mainacc_menulist .setting_menu_list_icon{ left: 7px;}
.mobile_account_item_login > a{ padding: 14px 0 10px 35px;}
.mobile_account_menu_click{     padding: 12px 30px 12px 10px; font-size: 14px}
.mobile_account_delivery_item a{font-size: 14px; padding: 10px 10px;}    
.mobile_account_menu_click i.arrow_myaccount{ background-size: 12px auto; width: 32px;}
.delivery_total_left {float: none; margin-bottom: 10px;width: 100%;text-align: center;}
.delivery_total_left.delivery_total_right {width: 100%;text-align: center;}
.reward-list-body .delivery_total_left {width: auto;}
.ttsplitter span{z-index: 1;}
.myacc_filter.small_responsive_row .filter_tabsec .nav-tabs>li>a, .small_responsive_rewards .myacc_filter .filter_tabsec .nav-tabs>li>a{font-size: 14px;}
.mobile-logo-div{ max-width: 160px }
.addon_poptop{padding: 0}    
.addon_popbot{ margin: 0 }    
.addon_popbot .row-replace{ -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center }    
.addon_linfo, .addon_lright, .pkselected_package {width: 100%;}    
#CartListMdl .total_amt { padding: 15px 15px;}    
.number-break { padding: 15px 5px 15px 5px;}    
.number-break .making-catering { padding: 60px 0 0;}   
.number-break .event-time{padding: 5px 0}    
.container { padding-left: 10px; padding-right: 10px;}    
a.go-bck-icon{top:-4px}    
h3.choose-eventtype { padding-left: 20px; }  
.hcart_round { top: -6px; }  
}
@media (max-width: 360px){ 
.reservation-summary ul li span{font-size: 11px;}
.reservation-summary{padding: 10px;}
.reservation-full h2{margin-bottom: 10px; font-size: 30px;}
.reservation-summary-full h3{font-size: 26px; margin-bottom: 12px;}
.footer-nav-info .foot-row .foot-total-col {justify-content: flex-start;min-width: auto;}
}

.close-icon-but {background: #000;}


@media (max-width: 1080px){
    .menu_icon.trigger_menunav_act {
        display: block;
        width: 30px;
        height: 19px;
        margin: 3px 0px;
        padding: 0px 0px;
        cursor: pointer;
    }

    .hmenu_sec {  display: none;}

    .menu_icon .icon-bar {
        display: block;
        background-color: #fff;
        height: 2px;
        transition: all 400ms;
    }
    .menu_icon .icon-bar+.icon-bar {
        margin-top: 6px;
    }
}


@media (max-width: 550px){
    .banner-info h2{
        font-size: 18px;
    }
    .banner-info h2 span {
        font-size: 15px;
    }
}

@media (max-width: 340px){ 
    .full-login-new-body .sign_reg p{
        font-size: 10px;
    }
    .full-login-new-body .sign_reg a{
        font-size: 10px;
    }
}

@media (min-width: 341px) and (max-width: 420px){ 
    .full-login-new-body .sign_reg p{
        font-size: 12px;
    }
    .full-login-new-body .sign_reg a{
        font-size: 12px;
    }
}
@media (min-width: 310px) and (max-width: 480px){
    .featured-products-main h2, .serving-passionately-right h3 {
        font-size: 21px;
        line-height: 30px;
    }
}

@media (min-width:360px) and (max-width: 460px){
    .htico_search a{
        width: 36px;
        height: 36px;
    }
    .htico_search> a > img{
        width: 19px;
        height: 21px;
    }
    .htico_cart > a{
        width: 36px;
        height: 36px;
    }
    .htico_cart> a img{
        width: 20px;
        height: 24px;
    }
    .hcart_round{
        top: -10px!important;
        min-width: 21px;
        min-height: 21px;
    }
    .mobile-login{
        width: 36px;
        height: 36px;
        left: 60px;
    }
}
